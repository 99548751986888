import {Dispatch} from "redux";

import {catch400, catch403, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestState} from "@pg-mono/request-state";
import {apiUserLink} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IRootState} from "../../store/store";
import {IUserProfile} from "../ts/interfaces/IUserProfile";
import {getUserApiRequestMeta} from "../utils/get_user_api_request_meta";

const FETCH_USER_PROFILE_PREFIX = "user/FETCH_USER_PROFILE";
export const fetchUserProfileRequestTypes = createRequestActionTypes(FETCH_USER_PROFILE_PREFIX);

const createFetchUserProfile = (forceFetch = false) => {
    return ({meta}: {meta: IRPRequestMeta} = {meta: {}}) =>
        async (dispatch: Dispatch, getState: () => IRootState): Promise<IUserProfile | null> => {
            const userApiMeta = getUserApiRequestMeta(meta);
            const userProfile = getState().user.profile;

            if (!forceFetch && userProfile.requestState !== RequestState.None) {
                return userProfile.data;
            }

            // continue with profile fetch
            dispatch({type: fetchUserProfileRequestTypes.start});

            return getRequest(userApiMeta, apiUserLink.user.info)
                .then((userProfile: IUserProfile) => {
                    dispatch({type: fetchUserProfileRequestTypes.success, result: userProfile});

                    return userProfile;
                })
                .catch(
                    catch403(() => {
                        dispatch({type: fetchUserProfileRequestTypes.reset});

                        return null;
                    })
                )
                .catch(
                    catch400((error) => {
                        dispatch({type: fetchUserProfileRequestTypes.error, error: error.appError});

                        return null;
                    })
                );
        };
};

export const resetFetchUserProfile = () => ({type: fetchUserProfileRequestTypes.reset});

export const fetchUserProfile = createFetchUserProfile(false);
export const forceFetchUserProfile = createFetchUserProfile(true);
