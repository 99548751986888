import {postRequest} from "@pg-mono/request";
import {apiLink} from "@pg-mono/rp-api-routes";

import {JsonValues} from "../../form/translate/to_json_values";
import {IUserProfile} from "../../user/ts/interfaces/IUserProfile";

const emailSignUpApiLink = apiLink.user.register({});

export const emailSignUp = (jsonData: JsonValues): Promise<IUserProfile> => {
    return postRequest({}, emailSignUpApiLink(null), jsonData);
};
