import {consoleError} from "@pg-mono/logger";
import {catch400, catch403, patchRequest} from "@pg-mono/request";
import {apiUserLink} from "@pg-mono/rp-api-routes";

import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {MarketingConsentType} from "../ts/enums/MarketingConsentType";
import {getUserApiRequestMeta} from "../utils/get_user_api_request_meta";

export const patchUserConsent = (data: Partial<Record<MarketingConsentType, boolean>>) => {
    const userApiMeta = getUserApiRequestMeta({});

    return patchRequest(userApiMeta, apiUserLink.user.preferences, data)
        .catch(
            catch403((error) => {
                consoleError("Error 403 in patchUserConsent", error.message);
            })
        )
        .catch(
            catch400((error) => {
                const message = "Error 400 in patchUserConsent";

                consoleError(message, error.message);
                notifyBugsnag(error, message);
            })
        );
};
