import {Fragment} from "react";

import {rpAppPath} from "@pg-mono/rp-routes";

import {INotificationValues} from "../../notifications/state/notifications_slice";

const favouritesLink = rpAppPath.favouriteList;

export const addOfferToFavouritesSuccess: INotificationValues = {
    type: "success",
    content: (
        <Fragment>
            Dodałeś inwestycję do Twojej listy <br /> <br />
            <strong>
                <a href={favouritesLink} target="_blank" title="Ulubione" style={{textDecoration: "underline"}}>
                    Zobacz listę
                </a>
            </strong>
            <br />
        </Fragment>
    )
};

export const addPropertyToFavouritesSuccess: INotificationValues = {
    type: "success",
    content: (
        <Fragment>
            Dodałeś nieruchomość do Porównywarki <br /> <br />
            <strong>
                <a href={favouritesLink} target="_blank" title="Ulubione" style={{textDecoration: "underline"}}>
                    Zobacz listę
                </a>
            </strong>
            <br />
        </Fragment>
    )
};

export const removeOfferFromFavouritesSuccess: INotificationValues = {
    type: "success",
    content: "Usunąłeś inwestycję z Twojej listy"
};

export const removePropertyFromFavouritesSuccess: INotificationValues = {
    type: "success",
    content: "Usunąłeś nieruchomość z Porównywarki"
};
