import type {Dispatch} from "redux";

import {ISentLeadStoredValues, setSentLeadFormValuesInStorage} from "../utils/sent_lead_form_values";

/**
 * State
 */
export interface ISentLeadState {
    storedValues: ISentLeadStoredValues | null;
}

const initialState = {
    storedValues: null
};

/**
 * Reducers
 */
export function sentLeadReducer(state = initialState, action: ISentLeadActions) {
    switch (action.type) {
        case SentLeadActionTypes.SET_SENT_LEAD:
            return {
                ...state,
                storedValues: action.payload
            };
        default:
            return state;
    }
}

/**
 * Actions
 */
enum SentLeadActionTypes {
    SET_SENT_LEAD = "SET_SENT_LEAD"
}

export type ISentLeadActions = ISetSentLeadValuesAction;

interface ISetSentLeadValuesAction {
    type: SentLeadActionTypes.SET_SENT_LEAD;
    payload: Partial<ISentLeadStoredValues>;
}

export function setSentLeadValuesAction(formValues: Partial<ISentLeadStoredValues>): ISetSentLeadValuesAction {
    const storedFormValues = setSentLeadFormValuesInStorage(formValues);

    return {
        type: SentLeadActionTypes.SET_SENT_LEAD,
        payload: storedFormValues
    };
}

export const setSentLeadValues = (formValues: Partial<ISentLeadStoredValues>) => (dispatch: Dispatch<ISetSentLeadValuesAction>) => {
    dispatch(setSentLeadValuesAction(formValues));
};
