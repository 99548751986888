import {Dispatch} from "redux";

import {userComUserRegister} from "@pg-mono/user-com";

import {IRPStore} from "../../app/rp_reducer";
import {loadFavouritesToStore} from "../../favourite/actions/load_favourites_to_store";
import {userSignInHit} from "../../tracking/algolytics_hits/user_sign_in_hit";
import {userSignUpHit} from "../../tracking/algolytics_hits/user_sign_up_hit";
import {UserSource} from "../../tracking/tracking_utils/user_source";
import {forceFetchUserProfile, resetFetchUserProfile} from "../../user/actions/fetch_user_profile";
import {fetchUserUnsubscribeLink} from "../../user/api/fetch_user_unsubscribe_link";
import {IUserProfile} from "../../user/ts/interfaces/IUserProfile";

/*
 * This action should be used only when user will log in or register (via login or register form) without use of the kontomieszkaniowe.pl
 * The only use cases for now are two-step application forms [CU-2t405gt] and hidden prices [CU-862j5xdx7]
 * For cases when the user is registered after application sent use `authUserAfterApplicationSent`
 */

export const afterInternalAuth =
    (afterRegister = false, userSource: UserSource = UserSource.REGISTRATION) =>
    async (dispatch: Dispatch, getState: () => IRPStore): Promise<IUserProfile | null> => {
        // Reset (clear) current user profile data if any
        dispatch(resetFetchUserProfile());

        const currentViewType = getState().viewType.current;

        // Re-fetch user profile data
        const userProfileData = await dispatch(forceFetchUserProfile());

        if (userProfileData) {
            await dispatch(loadFavouritesToStore());

            (afterRegister ? userSignUpHit : userSignInHit)(userSource, userProfileData, currentViewType);

            if (afterRegister) {
                const {email, uuid, email_marketing_consent, phone_marketing_consent} = userProfileData;

                fetchUserUnsubscribeLink().then(({unsubscribe_url_rp, unsubscribe_url_gh}) => {
                    userComUserRegister(uuid, email, email_marketing_consent, phone_marketing_consent, unsubscribe_url_rp, unsubscribe_url_gh);
                });
            }
        }

        return userProfileData;
    };
