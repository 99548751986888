import {useCallback, useState} from "react";

import {fetchClipPhoneByPropertyId} from "../api/fetch_clip_phone_by_property_id";

export const useClipPhoneNumber = (fallbackPhoneNumber?: string | null) => {
    const [phoneNumber, setPhoneNumer] = useState<string | null>();
    const [isFetching, setIsFetching] = useState(false);

    const fetchClipPhoneNumber = useCallback(
        async (propertyId?: number) => {
            if (propertyId) {
                setIsFetching(true);

                const data = await fetchClipPhoneByPropertyId(propertyId);
                setPhoneNumer(data?.clip_number || fallbackPhoneNumber);
                setIsFetching(false);
            } else {
                setPhoneNumer(fallbackPhoneNumber);
            }
        },
        [fallbackPhoneNumber]
    );

    return {
        phoneNumber,
        fetchClipPhoneNumber,
        isFetching
    };
};
