import React, {useId} from "react";
import {css, useTheme} from "@emotion/react";

import {FormikForm} from "@pg-design/formik-utils";
import {Popover} from "@pg-design/notifications";
import {Text} from "@pg-design/text";
import {useIsMobile} from "@pg-mono/hooks";

interface IProps {
    name: string;
    onFieldClick?: () => void;
    className?: string;
}

export const NotificationConsentField = (props: IProps) => {
    const {name, onFieldClick, className} = props;
    const isMobile = useIsMobile();
    const theme = useTheme();
    const id = useId();

    return (
        <FormikForm.Checkbox
            name={name}
            labelContent={
                <Text as="span" variant="info_txt_1" color={theme.colors.secondary}>
                    Chcę otrzymywać mailem lub SMSem linki do nowych ofert nieruchomości.{" "}
                    <span onClick={(e) => e.preventDefault()}>
                        <Popover
                            isActive
                            popoverPlace={isMobile ? "top" : "left"}
                            body={rodoWithdrawConsentPopoverText()}
                            toggleClickHandler
                            toggleOnClickOutside
                            inline
                        >
                            <span css={propertyNotificationConsentPopupHandle}>Zgodę mogę zawsze odwołać.</span>
                        </Popover>
                    </span>
                </Text>
            }
            id={id}
            onClick={onFieldClick}
            className={className}
            checkedMarkColor="#02D054"
        />
    );
};

const rodoWithdrawConsentPopoverText = () => {
    return (
        <Text variant="info_txt_2">
            Jeśli będziesz chciał(a) odwołać zgodę: skontaktuj się z nami lub wyślij komendę STOP na numer telefonu <br /> +48 664 078 606, albo kliknij w link
            służący do odwołania zgody znajdujący się w naszym newsletterze.
        </Text>
    );
};

const propertyNotificationConsentPopupHandle = css`
    text-decoration: underline;
`;
