import {Dispatch} from "redux";

import {createFavouritesActionTypes, reduceFavouritesToDataType} from "@pg-mono/favourites";
import {IFavourite} from "@pg-mono/favourites";

import {IRPStore} from "../../app/rp_reducer";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {getServerFavourite, updateFavourites} from "../api/update_favourites";
import {localStorageFavouritesService} from "./manage_local_storage_favourites";

const FAVOURITE = "favourite";
export const favouriteTypes = createFavouritesActionTypes(FAVOURITE);

const isErrorResponse = (error: unknown): error is {response: {status?: unknown}} => {
    return Object.prototype.hasOwnProperty.call(error, "response");
};

export const addToFavourites =
    (favourites: IFavourite[], updateServerState = true) =>
    async (dispatch: Dispatch, getState: () => IRPStore) => {
        const favouriteByDataType = reduceFavouritesToDataType(favourites);

        const isAuthenticated = getState().isAuthenticated;

        let favouritesList = [...favourites];

        if (isAuthenticated) {
            // During syncing local data with server state we don't need to update server state
            if (updateServerState) {
                try {
                    favouritesList = await updateFavourites(favourites.map((favourite) => getServerFavourite(favourite)));
                } catch (error) {
                    notifyBugsnag(
                        {message: `addToFavourites: ${isErrorResponse(error) ? error.response?.status : ""}`, filename: "manage_favourites.ts", error},
                        "API update favourites error during adding favourites"
                    );

                    return new Error(JSON.stringify(error));
                }
            }
        } else {
            localStorageFavouritesService.addToStorageFavourites(favourites);
        }

        dispatch({type: favouriteTypes.add, list: favouritesList, ...favouriteByDataType});
        /*
         * NOTE: The result of this action will always be handled by `then` method - event if `Promise.reject()` will be returned.
         * In order to distinguish the type of action result inside the component we need to return `true` or some other value
         * than `instanceof Error`.
         */
        return true;
    };

export const removeFromFavourites =
    (favourites: IFavourite[], updateServerState = true) =>
    async (dispatch: Dispatch, getState: () => IRPStore) => {
        const favouriteByDataType = reduceFavouritesToDataType(favourites);

        const isAuthenticated = getState().isAuthenticated;

        let favouritesList = [...favourites];

        if (isAuthenticated) {
            // During syncing local data with server state we don't need to update server state
            if (updateServerState) {
                try {
                    favouritesList = await updateFavourites(favourites.map((favourite) => getServerFavourite(favourite, true)));
                } catch (error) {
                    notifyBugsnag(
                        {message: `removeFromFavourites: ${isErrorResponse(error) ? error.response?.status : ""}`, filename: "manage_favourites.ts", error},
                        "API update favourites error during removing favourites"
                    );

                    return new Error(JSON.stringify(error));
                }
            }
        } else {
            localStorageFavouritesService.removeFromStorageFavourites(favourites);
        }

        dispatch({type: favouriteTypes.remove, list: favouritesList, ...favouriteByDataType});
        /*
         * NOTE: The result of this action will always be handled by `then` method - event if `Promise.reject()` will be returned.
         * In order to distinguish the type of action result inside the component we need to return `true` or some other value
         * than `instanceof Error`.
         */
        return true;
    };
