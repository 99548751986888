import React, {useEffect, useReducer} from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {p} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";
import {useABTest} from "@pg-mono/ab-testing";

import {IRPStore} from "../../app/rp_reducer";
import {IMarketplaceArchitectOfferSummary} from "../../architect_application/api/fetch_marketplace_architect_promoted_offers_by_region";
import {ArchitectMultileadModalContent} from "../../architect_application/components/ArchitectMultileadModalContent";
import {ArchitectOfferSummaryModalContent} from "../../architect_application/components/ArchitectOfferSummaryModalContent";
import {getPhoneDataFromString} from "../../form/utils/get_phone_data_from_string";
import {MultileadSentSummary} from "../../lead/components/MultileadSentSummary";
import {VendorMultilead} from "../../lead/components/vendor_multilead/VendorMultilead";
import {vendorMultileadABTestInstance} from "../../lead/constants/vendor_multilead_ab_test_instance";
import {useInitialLeadFormValues} from "../../lead/hooks/use_initial_lead_form_values";
import {useLeadData} from "../../lead/hooks/use_lead_data";
import {hitMultileadRecommendationShown} from "../../lead/tracking/hit_multilead_recommendation_shown";
import {IRealEstateIds} from "../../lead/types/IRealEstateIds";
import {MultileadVersion} from "../../lead/types/MultileadVersion";
import {RecommendationSource} from "../../lead/types/RecommendationSource";
import {getSentLeadFormValuesFromStorage} from "../../lead/utils/sent_lead_form_values";
import {fetchRecommendedOffers} from "../../offer/detail/actions/fetch_recommended_offers";
import {IRegionAncestor} from "../../offer/list/actions/offer_list_selected_data_actions";
import {fetchRecommendedProperties} from "../../property/actions/fetch_recommended_properties";
import {IRecommendedOffer} from "../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../recommendations/types/IRecommendedProperty";
import {Country} from "../../region/types/Country";
import {fadeInAnimation} from "../../search/components/atoms/atoms";
import {gtmStepApplicationClose} from "../../tracking/google_tag_manager/gtm_event_step_application_close";
import {fetchVendorRecommendations} from "../api/fetch_vendor_recommendations";
import {initialEmptyApplicationValues} from "../constants/application_form";
import {MULTI_LEAD_DISTANCES_IN_KM, VENDOR_MULTI_LEAD_DISTANCE_IN_KM} from "../constants/multi_lead_constants";
import {useApplicationVariant} from "../hooks/use_application_variant";
import {initialStepState, ModalStepActionTypes, modalStepReducer} from "../reducers/modal_step_reducer";
import {ApplicationVariant} from "../types/ApplicationVariant";
import {IApplication} from "../types/IApplication";
import {IApplicationOffer} from "../types/IApplicationOffer";
import {IApplicationProperty} from "../types/IApplicationProperty";
import {IMultileadOrAfterSummaryStepPayload} from "../types/IMultileadOrAfterSummaryStepPayload";
import {IMultileadSummaryStepState} from "../types/IMultileadSummaryStepState";
import {IOriginalApplicationProperty} from "../types/IOriginalApplicationProperty";
import {LeadModalStep} from "../types/LeadModalStep";
import {MultiLeadRecommendationType} from "../types/MultiLeadRecommendationType";
import {ApplicationSource, ApplicationSourceSection} from "../utils/ApplicationSource";
import {isAbroad} from "../utils/is_abroad";
import {getStorageSentApplicationsIdListByOffer} from "../utils/manage_storage_sent_applications";
import {report} from "../utils/recommended_application/analytics_events";
import {MultiLead} from "./multi_lead/MultiLead";
import {AppliedPMOffer} from "./multi_lead_promo/AppliedPMOffer";
import {PMPromoModalLayout} from "./multi_lead_promo/PMPromoModalLayout";
import {Application, IApplicationOwnProps} from "./Application";
import {ApplicationGoodbyeContentModal} from "./ApplicationGoodbyeContentModal";
import {ApplicationStyledModal} from "./ApplicationStyledResponsiveModal";
import {AppliedArchitectInteriorDesign} from "./AppliedArchitectInteriorDesign";
import {AppliedProperties} from "./AppliedProperties";
import {FormSuccessInfoContainer} from "./FormSuccessInfoContainer";
import {NotificationConsentConfirmation} from "./NotificationConsentConfirmation";

type IProps = Omit<IApplicationOwnProps, "leadData" | "initialLeadFormValues"> & {
    closeModal: () => void;
    isMobile: boolean;
    isOpened: boolean;
    modalArrayClassNames?: string[];
    initialStep?: LeadModalStep;
    preventClosingWhenNoData?: boolean;
    customSourceSection?: string;
    applicationVariant?: ApplicationVariant;
    creditPayload?: {price: number; contribution: number; period: number};
    customFormText?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ApplicationModalArray = ({closeModal, isOpened, initialStep, preventClosingWhenNoData, customFormText, ...restProps}: IProps) => {
    const dispatch = useDispatch();
    const applicationVariant = useApplicationVariant({
        applicationVariant: restProps.applicationVariant,
        offerId: restProps.offerId,
        propertyId: restProps.propertyId
    });

    const parametrizedStoreHash = getStoreHash(restProps.storeHash, {
        vendorId: restProps.vendorId,
        offerId: restProps.offerId,
        propertyId: restProps.propertyId
    });

    const roomCount = useSelector((store: IRPStore) => store.application[parametrizedStoreHash]?.property?.detail?.rooms) as number;

    const leadData = useLeadData({
        storeHash: parametrizedStoreHash,
        vendorId: restProps.vendorId,
        offerId: restProps.offerId,
        propertyId: restProps.propertyId,
        skipFetch: !isOpened
    });

    const initialLeadFormValues = useInitialLeadFormValues({
        storeHash: parametrizedStoreHash,
        leadData,
        realEstateIds: {offerId: restProps.offerId, propertyId: restProps.propertyId, vendorId: restProps.vendorId},
        source: restProps.source,
        resetValues: !isOpened,
        /*
            TODO: IApplicationOwnProps seems to have promotion and preventStorageOfferSearchLoad in its interface, but those doesn't seem to be passed anywhere
                Analyze and remove from implementation or fix missing props passing in some place
         */
        promotion: restProps.promotion,
        preventStorageOfferSearchLoad: restProps.preventStorageOfferSearchLoad,
        customFormText
    });

    /**
     * State
     */
    const isAutoresponderEnabled = useSelector(
        (state: IRPStore) => state.application[parametrizedStoreHash]?.offer?.detail?.configuration.is_autoresponder_enabled
    );
    const currentViewType = useSelector((store: IRPStore) => store.viewType.current);

    const [modalStepState, dispatchModalStepState] = useReducer(modalStepReducer, {
        ...initialStepState,
        ...(initialStep && {step: initialStep}),
        isAutoresponderEnabled: !!isAutoresponderEnabled
    });

    const {abTestVariant: vendorMultileadAbTestVariant} = useABTest(vendorMultileadABTestInstance);

    /**
     * Callback
     */
    useEffect(() => {
        if (isOpened && !initialStep) {
            dispatchModalStepState({type: ModalStepActionTypes.OPEN});
        }
    }, [isOpened]);

    useEffect(() => {
        if (modalStepState.step === LeadModalStep.CLOSE) {
            closeModal();
        }
    }, [modalStepState.step]);

    useEffect(() => {
        // `isAutoresponderEnabled` is value from API, so we need to update this value in reducer state
        if (isOpened && isAutoresponderEnabled) {
            dispatchModalStepState({type: ModalStepActionTypes.SET_IS_AUTORESPONDER_ENABLED, payload: {isAutoresponderEnabled}});
        }
    }, [isAutoresponderEnabled, isOpened]);

    useEffect(() => {
        if (leadData) {
            dispatchModalStepState({type: ModalStepActionTypes.UPDATE_LEAD_DATA, payload: leadData});
        }
    }, [leadData, isOpened]);

    const idProps: IRealEstateIds = {
        vendorId: restProps.vendorId,
        offerId: restProps.offerId,
        propertyId: restProps.propertyId
    };

    const recommendationType = idProps.propertyId ? MultiLeadRecommendationType.PROPERTY : MultiLeadRecommendationType.OFFER;

    // `forceClose` - close without any additional modals
    const onModalClose = (forceCloseParam?: boolean) => {
        if (modalStepState.step === LeadModalStep.APPLICATION_FORM) {
            gtmStepApplicationClose();
        }

        if (modalStepState.step === LeadModalStep.RECOMMENDED_OFFERS) {
            report.modalClose(recommendationType, currentViewType);
        }

        const forceClose = forceCloseParam || restProps.forceClose || false;
        dispatchModalStepState({type: ModalStepActionTypes.CLOSE, payload: {forceClose}});
    };

    const fetchRecommendations = async (
        offerId: number | undefined | null,
        propertyId: number | undefined | null,
        numberOfRooms: number[] | undefined | null,
        excludedOfferIds: number[],
        filters: Record<string, string> = {}
    ) => {
        // PropertyId will only exist if multilead was fetched for property application. Otherwise, it is an offer multilead.
        if (propertyId) {
            if (filters.max_price && offerId) {
                const recommendedProperties = await dispatch(fetchRecommendedProperties(propertyId, [], {...filters, offer: offerId}));

                return recommendedProperties.length ? recommendedProperties : await dispatch(fetchRecommendedProperties(propertyId, excludedOfferIds, filters));
            }

            return await dispatch(fetchRecommendedProperties(propertyId, excludedOfferIds, filters));
        }

        if (offerId) {
            return await dispatch(fetchRecommendedOffers(offerId, numberOfRooms, excludedOfferIds));
        }

        return null;
    };

    const onAfterRecommendedSuccess = (originalApplicationResponse: IApplication | null) => {
        dispatchModalStepState({
            type: ModalStepActionTypes.NEXT_STEP,
            payload: {originalApplicationResponse}
        });
    };

    const onShowArchitectOfferButtonClick = (architectOfferDetails: IMarketplaceArchitectOfferSummary, architectRegion: IRegionAncestor | null) => {
        dispatchModalStepState({
            type: ModalStepActionTypes.NEXT_STEP,
            payload: {architectOfferDetails, architectRegion}
        });
    };

    const onShowPmOfferButtonClick = () => {
        dispatchModalStepState({
            type: ModalStepActionTypes.NEXT_STEP,
            payload: {
                showPmOffer: true
            }
        });
    };

    const onArchitectOfferApplied = (architectOfferDetails: IMarketplaceArchitectOfferSummary, architectRegion: IRegionAncestor | null) => {
        dispatchModalStepState({
            type: ModalStepActionTypes.NEXT_STEP,
            payload: {architectOfferDetails, architectRegion}
        });
    };

    const dispatchNextStepWithoutPayload = () => {
        dispatchModalStepState({
            type: ModalStepActionTypes.NEXT_STEP
        });
    };

    const setSingleLeadSummaryStep = (originalApplicationResponse: IApplication | null) => {
        dispatchModalStepState({
            type: ModalStepActionTypes.SINGLE_LEAD_SUMMARY_STEP,
            payload: {
                originalApplicationResponse
            }
        });
    };

    const setMultileadSummaryStep = (payload: IMultileadSummaryStepState) => {
        dispatchModalStepState({
            type: ModalStepActionTypes.NEXT_STEP,
            payload
        });
    };

    /**
     * Render
     */

    const splitModal = modalStepState.step === LeadModalStep.APPLICATION_FORM || modalStepState.step === LeadModalStep.GOODBYE_INFO;

    const renderContent = () => {
        const onAfterSuccess = async (
            sentApplication: IApplication | null,
            offer?: IApplicationOffer,
            property?: IApplicationProperty,
            recommendedFilters?: Record<string, string>,
            onBeforeNextStep?: () => Promise<void>
        ) => {
            const excludedOfferIds = getStorageSentApplicationsIdListByOffer(offer, {limit: 20, distance: MULTI_LEAD_DISTANCES_IN_KM[1] * 1000});
            // In case of NOT ADDING the radius to fetch payload, update FormSuccessInfoContainer tracking hit
            let multiLeadOfferRadius = MULTI_LEAD_DISTANCES_IN_KM[0];

            let recommendedList: IRecommendedProperty[] | IRecommendedOffer[] | null = null;
            let multiLeadProperty: IOriginalApplicationProperty | null = property;

            if (vendorMultileadAbTestVariant === 0) {
                recommendedList = await fetchRecommendations(offer?.id, property?.id, sentApplication?.rooms, excludedOfferIds, {
                    ...recommendedFilters,
                    radius: multiLeadOfferRadius.toString()
                });
                if (!Array.isArray(recommendedList)) {
                    // if list is empty, re-fetch recommended list with longer radius
                    multiLeadOfferRadius = MULTI_LEAD_DISTANCES_IN_KM[1];
                    recommendedList = await fetchRecommendations(offer?.id, property?.id, sentApplication?.rooms, excludedOfferIds, {
                        ...recommendedFilters,
                        radius: multiLeadOfferRadius.toString()
                    });
                }
            }

            if (vendorMultileadAbTestVariant === 1) {
                const vendorRecommendations = await fetchVendorRecommendations(
                    {
                        offerId: offer?.id,
                        originalApplicationProperty: property,
                        excludedOfferIds,
                        radius: VENDOR_MULTI_LEAD_DISTANCE_IN_KM
                    },
                    dispatch
                );

                recommendedList = vendorRecommendations.results;
                multiLeadProperty = vendorRecommendations.leadProperty;
            }

            if ((!recommendedList || recommendedList?.length === 0) && onBeforeNextStep) {
                await onBeforeNextStep();
            }

            if (typeof restProps.onSuccessfulSubmit === "function") {
                restProps.onSuccessfulSubmit(sentApplication as IApplication);
            }

            if (!recommendedList || recommendedList.length === 0) {
                const recommendationsType = property ? MultiLeadRecommendationType.PROPERTY : MultiLeadRecommendationType.OFFER;
                const abRecommendationsType = MultiLeadRecommendationType.VENDOR;
                const recommendationSource =
                    restProps.source === ApplicationSource.ApplicationOfferScheduleMeeting ? RecommendationSource.MODEL : RecommendationSource.DISTANCE;

                hitMultileadRecommendationShown({
                    recommendationType: vendorMultileadAbTestVariant === 0 ? recommendationsType : abRecommendationsType,
                    recommendationId: null,
                    viewType: currentViewType,
                    recommendationSource: recommendationSource,
                    multileadVersion:
                        recommendationsType === MultiLeadRecommendationType.PROPERTY ? MultileadVersion.RP_LIST_PROPERTY : MultileadVersion.RP_LIST_OFFER
                });
            }

            const nextStepPayload: IMultileadOrAfterSummaryStepPayload = {
                originalApplicationResponse: sentApplication ? sentApplication : null,
                recommendedOffers: recommendedList,
                multiLeadProperty: multiLeadProperty,
                recommendationsWithPriceFilter: recommendedFilters ? "max_price" in recommendedFilters : false,
                // If all recommendations has the same offer id then we have multilead from same offer
                sameOfferRecommendedProperties:
                    property?.id && Array.isArray(recommendedList)
                        ? !(recommendedList as IRecommendedProperty[]).some((recommended) => recommended.offer.id !== offer?.id)
                        : false
            };

            dispatchModalStepState({
                type: ModalStepActionTypes.NEXT_STEP,
                payload: nextStepPayload
            });
        };

        // render cases
        switch (modalStepState.step) {
            case LeadModalStep.APPLICATION_FORM: {
                return (
                    <>
                        {(!initialLeadFormValues || !leadData) && <Loader size="lg" fullWidth />}
                        {initialLeadFormValues && leadData && (
                            <Application
                                {...idProps}
                                initialLeadFormValues={initialLeadFormValues}
                                leadData={leadData}
                                onSuccessfulSubmit={onAfterSuccess}
                                hideMessageSection={restProps.hideMessageSection}
                                storeHash={parametrizedStoreHash}
                                source={restProps.source}
                                sourceSection={restProps.sourceSection}
                                salesOfficeId={restProps.salesOfficeId}
                                promotion={restProps.promotion}
                                promotionDateString={restProps.promotionDateString}
                                isModal={restProps.isModal}
                                preventStorageOfferSearchLoad={restProps.preventStorageOfferSearchLoad}
                                hideRooms={restProps.hideRooms}
                                alwaysShowFields
                                hideRodo={window.innerWidth > 1024}
                                hideSocialLoginButtons
                                hideSubmitButton={false}
                                hideHeaderDivider={false}
                                affiliation={restProps.affiliation}
                                closeModal={onModalClose}
                                hideQuestions={restProps.hideQuestions ?? leadData.offer?.detail?.region.country !== Country.POLAND}
                                submitOnMount={restProps.submitOnMount}
                                applicationVariant={applicationVariant}
                                creditPayload={restProps.creditPayload}
                                customFormText={customFormText}
                            />
                        )}
                    </>
                );
            }
            case LeadModalStep.NOTIFICATION_CONSENT: {
                return (
                    <NotificationConsentConfirmation
                        {...restProps}
                        storeHash={parametrizedStoreHash}
                        result={modalStepState.originalApplicationResponse}
                        onSuccessfulSubmit={onAfterSuccess}
                        onModalClose={onModalClose}
                    />
                );
            }
            case LeadModalStep.SUCCESS_INFO: {
                return (
                    <FormSuccessInfoContainer
                        isModal
                        modalStepState={modalStepState}
                        onClick={() =>
                            dispatchModalStepState({
                                type: ModalStepActionTypes.NEXT_STEP
                            })
                        }
                        shouldRenderRecommendations
                        onModalClose={onModalClose}
                    />
                );
            }
            case LeadModalStep.GOODBYE_INFO: {
                return <ApplicationGoodbyeContentModal {...restProps} storeHash={parametrizedStoreHash} closeModal={onModalClose} />;
            }
            case LeadModalStep.RECOMMENDED_OFFERS: {
                const sentLeadFormValues = getSentLeadFormValuesFromStorage();

                if (!sentLeadFormValues && !preventClosingWhenNoData) {
                    // application data should be filled at this point. But just in case:
                    onModalClose();

                    return null;
                }

                const originalApplicationFormValues = sentLeadFormValues
                    ? {...sentLeadFormValues, phone: getPhoneDataFromString(sentLeadFormValues.phone)}
                    : {...initialEmptyApplicationValues, rooms: [roomCount]};

                const isAbroadLead = isAbroad(leadData?.offer?.detail?.region.country);

                if (vendorMultileadAbTestVariant === 1 && !isAbroadLead && leadData?.offer) {
                    return (
                        <VendorMultilead
                            /**
                             * Currently, modalStepReducer checks for recommendations (payload.recommendedOffers.length > 0).
                             * Additionally, for vendorMultileadRecommendations, we only fetch for properties (fetchVendorRecommendations).
                             * Therefore, we can safely cast vendorMultileadRecommendations to IRecommendedProperty[]
                             */
                            storeHash={parametrizedStoreHash}
                            recommendations={modalStepState.recommendedOffers as IRecommendedProperty[]}
                            originalApplicationFormValues={originalApplicationFormValues}
                            originalApplicationResponse={modalStepState.originalApplicationResponse}
                            multiLeadProperty={modalStepState.multiLeadProperty}
                            handleSetMultiLeadSummaryStep={setMultileadSummaryStep}
                            handleSetSingleLeadSummaryStep={setSingleLeadSummaryStep}
                            handleModalClose={onModalClose}
                        />
                    );
                }

                return (
                    <MultiLead
                        {...idProps}
                        originalApplicationResponse={modalStepState.originalApplicationResponse}
                        originalApplicationFormValues={originalApplicationFormValues}
                        storeHash={parametrizedStoreHash}
                        sourceSection={ApplicationSourceSection.MULTILEAD}
                        onModalClose={onModalClose}
                        onSuccessfulSubmit={setSingleLeadSummaryStep}
                        recommendationType={recommendationType}
                        recommendationsWithPriceFilter={modalStepState.recommendationsWithPriceFilter}
                        sameOfferRecommendedProperties={modalStepState.sameOfferRecommendedProperties}
                        customSourceSection={restProps.customSourceSection}
                        handleSetMultiLeadSummaryStep={setMultileadSummaryStep}
                    />
                );
            }
            case LeadModalStep.MULTILEAD_SUMMARY: {
                return (
                    <MultileadSentSummary
                        storeHash={parametrizedStoreHash}
                        leadModalStepState={modalStepState}
                        handleSetNextStep={onAfterRecommendedSuccess}
                        handleCloseModal={onModalClose}
                        handleSetArchitectInteriorDesignOfferStep={onShowArchitectOfferButtonClick}
                        handleSetPmOfferStep={onShowPmOfferButtonClick}
                    />
                );
            }
            case LeadModalStep.SINGLE_LEAD_SUMMARY: {
                return <AppliedProperties storeHash={parametrizedStoreHash} onShowArchitectOfferButtonClick={onShowArchitectOfferButtonClick} />;
            }
            case LeadModalStep.ARCHITECT_OFFER: {
                return (
                    <ArchitectOfferSummaryModalContent
                        storeHash={parametrizedStoreHash}
                        architectOfferDetails={modalStepState.architectOfferDetails}
                        architectRegion={modalStepState.architectRegion}
                        onArchitectOfferApplied={onArchitectOfferApplied}
                        userData={{
                            name: modalStepState.originalApplicationResponse?.name,
                            email: modalStepState.originalApplicationResponse?.email,
                            phone: modalStepState.originalApplicationResponse?.phone
                        }}
                    />
                );
            }
            case LeadModalStep.PM_OFFER: {
                return (
                    <PMPromoModalLayout
                        originalApplicationResponse={modalStepState.originalApplicationResponse}
                        storeHash={parametrizedStoreHash}
                        leadData={leadData}
                        onSuccessfulSubmit={dispatchNextStepWithoutPayload}
                    />
                );
            }
            case LeadModalStep.PM_APPLIED_OFFER: {
                return <AppliedPMOffer onCloseButtonClick={dispatchNextStepWithoutPayload} />;
            }
            case LeadModalStep.APPLIED_ARCHITECT_OFFER: {
                return (
                    <AppliedArchitectInteriorDesign
                        architectOfferDetails={modalStepState.architectOfferDetails}
                        onCloseButtonClick={dispatchNextStepWithoutPayload}
                    />
                );
            }
            case LeadModalStep.ARCHITECT_MULTILEAD: {
                return (
                    <ArchitectMultileadModalContent
                        region={modalStepState.architectRegion?.name}
                        userData={{
                            name: modalStepState.originalApplicationResponse?.name || initialLeadFormValues?.name || "",
                            email: modalStepState.originalApplicationResponse?.email || initialLeadFormValues?.email || "",
                            phone:
                                modalStepState.originalApplicationResponse?.phone ||
                                `${initialLeadFormValues?.phone.code} ${initialLeadFormValues?.phone.number}` ||
                                "",
                            area: ""
                        }}
                        mainAppliedArchitectOffer={modalStepState.architectOfferDetails}
                    />
                );
            }
            default:
                return <span />;
        }
    };

    const shouldCloseOnOverlayClick =
        restProps.submitOnMount && modalStepState.step === LeadModalStep.APPLICATION_FORM ? false : modalStepState.shouldCloseOnOverlayClick;

    return (
        <FormModal
            isOpen={isOpened}
            onModalClose={() => onModalClose(false)}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            css={getModalStyle(modalStepState.step)}
            showCloseButton={![LeadModalStep.RECOMMENDED_OFFERS, LeadModalStep.APPLICATION_FORM, LeadModalStep.GOODBYE_INFO].includes(modalStepState.step)}
        >
            {modalStepState.step === LeadModalStep.APPLICATION_FORM ? (
                <div css={newApplicationWrapStyle}>
                    <div css={newApplicationHolderStyle}>{renderContent()}</div>
                </div>
            ) : (
                <ApplicationWrapper>
                    <ApplicationHolder wide={!splitModal}>{renderContent()}</ApplicationHolder>
                </ApplicationWrapper>
            )}
        </FormModal>
    );
};

// Styles
const getModalStyle = (step: LeadModalStep) => (theme: Theme) => {
    switch (step) {
        case LeadModalStep.NOTIFICATION_CONSENT:
        case LeadModalStep.SUCCESS_INFO:
            return css`
                @media (min-width: ${theme.breakpoints.md}) {
                    width: 896px;
                    height: auto;
                }
            `;
        case LeadModalStep.GOODBYE_INFO:
            return css`
                @media (min-width: ${theme.breakpoints.md}) {
                    width: 896px;
                    height: 644px;
                }
            `;
        case LeadModalStep.RECOMMENDED_OFFERS:
        case LeadModalStep.MULTILEAD_SUMMARY:
            return css`
                @media (min-width: ${theme.breakpoints.md}) {
                    width: 1000px;
                    max-height: 700px;
                }
            `;

        case LeadModalStep.SINGLE_LEAD_SUMMARY:
            return css`
                @media (min-width: ${theme.breakpoints.md}) {
                    width: 936px;
                    height: 644px;
                }
            `;
        case LeadModalStep.ARCHITECT_OFFER:
            return css`
                @media (min-width: ${theme.breakpoints.md}) {
                    width: 950px;
                    height: auto;
                }
            `;
        case LeadModalStep.APPLIED_ARCHITECT_OFFER:
            return css`
                @media (min-width: ${theme.breakpoints.md}) {
                    width: 860px;
                    height: auto;
                }
            `;
        case LeadModalStep.PM_OFFER:
            return css`
                @media (min-width: ${theme.breakpoints.md}) {
                    width: 950px;
                    height: 520px;
                }
            `;
        case LeadModalStep.PM_APPLIED_OFFER:
            return css`
                @media (min-width: ${theme.breakpoints.md}) {
                    width: 850px;
                    height: auto;
                }
            `;
        case LeadModalStep.ARCHITECT_MULTILEAD:
            return css`
                width: 100%;

                @media (min-width: ${theme.breakpoints.md}) {
                    max-width: 745px;
                    height: auto;
                }
            `;
    }
};

const FormModal = styled(ApplicationStyledModal)`
    ${fadeInAnimation("0.1s")};
    ${p(0)};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        width: unset;
        height: unset;
    }
`;

const newApplicationWrapStyle = css`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
    height: 100%;
`;

const newApplicationHolderStyle = css`
    width: 100%;
    min-width: 28rem;
    overflow-y: auto;
    position: relative;
`;

const ApplicationWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
    height: 100%;
`;

interface IApplicationHolderProps {
    wide?: boolean;
}

const ApplicationHolder = styled.div<IApplicationHolderProps>`
    width: 100%;
    height: 100%;
    min-width: 28rem;
    overflow-y: auto;
    position: relative;
`;

function getStoreHash(storeHash: string, ids: {vendorId: number; offerId?: number; propertyId?: number}) {
    const offerText = ids.offerId ? `o${ids.offerId}` : "";
    const propertyText = ids.propertyId ? `p${ids.propertyId}` : "";

    return `v${ids.vendorId}${offerText}${propertyText}`;
}
