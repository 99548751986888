import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {Text} from "@pg-design/text";
import {rpAppLink} from "@pg-mono/rp-routes";

interface IProps {
    color?: string;
    className?: string;
    isPartnerTerms?: boolean;
    sourceForm?: {
        source: string;
        isUserLogged?: boolean;
    };
}
export const TermsAcceptation: React.FunctionComponent<IProps> = (props) => {
    const theme = useTheme();
    const pdfLink = rpAppLink.partnersTerms.base();
    const termsLink = props.isPartnerTerms ? pdfLink : rpAppLink.termsOfService.base();

    return (
        <Text variant="info_txt_2" color={props.color || theme.colors.gray[700]} className={props.className} align="center">
            Akceptuję{" "}
            <a href={termsLink} target="_blank" css={link}>
                regulamin{!props.sourceForm?.isUserLogged ? "" : " serwisu."}
            </a>
            {props.sourceForm?.source === "applicationSideBar" && !props.sourceForm?.isUserLogged ? " i zakładam konto w Portalu." : ""}
        </Text>
    );
};

const link = (theme: Theme) => css`
    text-decoration: underline;
    color: ${theme.colors.gray[700]};

    &,
    &:link,
    &:visited,
    &:hover,
    &:active {
        text-decoration: underline;
        color: ${theme.colors.gray[700]};
    }
`;
